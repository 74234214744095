import type {
  DeletableGroupDefinition,
  DeletableGroupDefinitions,
  GroupDefinition,
  GroupDefinitionsUpdateV2,
} from '@orus.eu/activity'
import { TechnicalError } from '@orus.eu/error'
import { assertIsOperatingZone, operatingZones, type OperatingZone } from '@orus.eu/operating-zone'
import deepEqual from 'deep-equal'

export type GroupDefinitionsUpdateDiff = {
  /**
   * Technical definition of the update
   */
  update: GroupDefinitionsUpdateV2
  /**
   * UI-friendly description of groups that were added in the process
   */
  addedGroups: GroupDescription[]
  /**
   * UI-friendly description of groups that were removed in the process
   */
  removedGroups: GroupDescription[]
  /**
   * UI-friendly description of groups that were updated in the process
   */
  updatedGroups: GroupDescription[]
}

export type GroupDescription = {
  name: string
  operatingZone: OperatingZone
} & Omit<GroupDefinition, 'deleted'>

export function isDiffEmpty(diff: GroupDefinitionsUpdateDiff): boolean {
  return Object.keys(diff.update).length === 0
}

export function computeDiff(a: DeletableGroupDefinitions, b: DeletableGroupDefinitions): GroupDefinitionsUpdateDiff {
  const updatesByNameByZone: Record<OperatingZone, Record<string, DeletableGroupDefinition>> = { fr: {}, es: {} }
  const addedGroups: GroupDescription[] = []
  const removedGroups: GroupDescription[] = []
  const updatedGroups: GroupDescription[] = []

  const unseenOldGroupNames: Record<OperatingZone, Set<string>> = {
    fr: new Set(Object.keys(a['fr'])),
    es: new Set(Object.keys(a['es'])),
  }

  for (const operatingZone of operatingZones) {
    for (const entry of Object.entries(b[operatingZone])) {
      const name = entry[0]
      const definitionA = a[operatingZone][name] ? cleanDeletableGroupDefinition(a[operatingZone][name]) : undefined
      const definitionB = cleanDeletableGroupDefinition(entry[1])

      unseenOldGroupNames[operatingZone].delete(name)

      const { activities, aliases, weight, deleted, categories, description, forbiddenMonthlyPayment } = definitionB

      if (definitionA && !definitionA.deleted && definitionB.deleted) {
        updatesByNameByZone[operatingZone][name] = definitionB
        removedGroups.push({
          name,
          operatingZone,
          activities,
          aliases,
          weight,
          categories,
          description,
          forbiddenMonthlyPayment,
        })
        continue
      }

      if ((!definitionA || definitionA.deleted) && !deleted) {
        updatesByNameByZone[operatingZone][name] = definitionB
        addedGroups.push({
          name,
          operatingZone,
          activities,
          aliases,
          weight,
          categories,
          description,
          forbiddenMonthlyPayment,
        })
        continue
      }

      if (
        !definitionA ||
        definitionA.weight !== definitionB.weight ||
        !deepEqual(definitionA.aliases, definitionB.aliases) ||
        !deepEqual(definitionA.activities, definitionB.activities) ||
        !deepEqual(definitionA.categories, definitionB.categories) ||
        definitionA.description != definitionB.description ||
        definitionA.forbiddenMonthlyPayment != definitionB.forbiddenMonthlyPayment
      ) {
        updatesByNameByZone[operatingZone][name] = definitionB
        updatedGroups.push({
          name,
          operatingZone,
          activities,
          aliases,
          weight,
          categories,
          description,
          forbiddenMonthlyPayment,
        })
        continue
      }

      if (!deepEqual(definitionA, definitionB)) {
        throw new TechnicalError('Unhandled difference between group definitions', {
          context: { definitionA, definitionB },
        })
      }
    }
  }

  for (const operatingZone of operatingZones) {
    for (const name of unseenOldGroupNames[operatingZone]) {
      const definitionA = a[operatingZone][name]
      const { activities, aliases, weight, categories, description, forbiddenMonthlyPayment } = definitionA

      updatesByNameByZone[operatingZone][name] = { ...definitionA, deleted: true }
      removedGroups.push({
        name,
        operatingZone,
        activities,
        aliases,
        weight,
        categories,
        description,
        forbiddenMonthlyPayment,
      })
    }
  }

  const update: GroupDefinitionsUpdateV2 = Object.entries(updatesByNameByZone).flatMap(([zone, groupsByName]) => {
    assertIsOperatingZone(zone)
    return Object.entries(groupsByName).map(([name, groupDefinition]) => ({
      name,
      operatingZone: zone,
      ...groupDefinition,
    }))
  })

  return { update, addedGroups, removedGroups, updatedGroups }
}

function cleanDeletableGroupDefinition(definition: DeletableGroupDefinition): DeletableGroupDefinition {
  return {
    weight: definition.weight,
    aliases: definition.aliases,
    activities: definition.activities,
    categories: definition.categories,
    description: definition.description,
    forbiddenMonthlyPayment: definition.forbiddenMonthlyPayment,
    deleted: definition.deleted,
  }
}
