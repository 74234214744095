import type { DeletableGroupDefinitions } from '@orus.eu/activity'
import { operatingZones } from '@orus.eu/operating-zone'
import { trpc } from '../../../../../client'

export async function buildGroupsActivitiesExport(groupsDefinitions: DeletableGroupDefinitions): Promise<string> {
  const activityLabels = await trpc.activities.getActivitiesHints.query()

  const lines = [
    ['Operating zone', 'Orus group name', 'Orus activity id', 'Grouping constraint', 'Risk carrier activity name'],
  ]
  const firstAliasColumnIndex = lines[0].length

  for (const operatingZone of operatingZones) {
    for (const [groupName, groupDefinition] of Object.entries(groupsDefinitions[operatingZone])) {
      if (groupDefinition.deleted) continue

      for (const activity of groupDefinition.activities) {
        const label = activityLabels[activity]
        const [groupingContraint, riskCarrierLabel] = label.includes('|')
          ? label.split('|').map((s) => s.trim())
          : ['', label]
        lines.push([operatingZone, groupName, activity, groupingContraint, riskCarrierLabel])
      }

      for (let aliasIndex = 0; aliasIndex < groupDefinition.aliases.length; aliasIndex++) {
        const aliasColumnIndex = firstAliasColumnIndex + aliasIndex
        lines[0][aliasColumnIndex] = `Orus alias ${aliasIndex + 1}`
        lines[lines.length - 1][aliasColumnIndex] = groupDefinition.aliases[aliasIndex]
      }
    }
  }

  return lines.map((line) => line.join('\t')).join('\n')
}

export function buildGroupsAliasesExport(groupsDefinitions: DeletableGroupDefinitions): string {
  const lines = [['Operating zone', 'Orus group name', 'Orus group alias']]
  for (const operatingZone of operatingZones) {
    for (const [groupName, groupDefinition] of Object.entries(groupsDefinitions[operatingZone])) {
      if (groupDefinition.deleted) continue
      for (const alias of groupDefinition.aliases) {
        lines.push([operatingZone, groupName, alias])
      }
    }
  }
  return lines.map((line) => line.join('\t')).join('\n')
}
