import { isActivity } from '@orus.eu/activity'
import { TechnicalError } from '@orus.eu/error'
import { assertSuccess } from '@orus.eu/result'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { trpc } from '../../../../../client'
import { logger } from '../../../../../lib/logger'
import { noop } from '../../../../../lib/noop'
import { sessionManager } from '../../../../../lib/session'

export function ensureActivityEditorHintsInitialized(): void {
  if (inlayHintsInitialized) return
  inlayHintsInitialized = true
  provideInlayHints().catch(logger.error)
}

let inlayHintsInitialized = false

async function provideInlayHints(): Promise<void> {
  // wait for the session to be initialized before retrieving private data using the API
  assertSuccess(await sessionManager.getSessionResult())

  const activityLabels = await trpc.activities.getActivitiesHints.query()

  monaco.languages.registerInlayHintsProvider('json', {
    provideInlayHints(model, range) {
      const rangeContent = model.getValueInRange(range)
      const rangeLines = rangeContent.split(model.getEOL())

      const hints = rangeLines.flatMap((rangeLine, rangeLineIndex) => {
        const parsingResult = parseSingleStringLineInJson(rangeLine)
        if (!parsingResult) return []

        const { closingQuoteIndex, value } = parsingResult
        if (!isActivity(value)) return []

        const label = activityLabels[value]
        if (!label) return []

        return {
          kind: monaco.languages.InlayHintKind.Type,
          position: { column: closingQuoteIndex + 3, lineNumber: range.startLineNumber + rangeLineIndex },
          label: activityLabels[value],
          paddingLeft: true,
        } satisfies monaco.languages.InlayHint
      })

      return {
        hints,
        dispose: noop, // didn't identify something I should do here for now
      }
    },
  })
}

function parseSingleStringLineInJson(line: string): { closingQuoteIndex: number; value: string } | null {
  line = line.trimEnd()
  if (line.endsWith(',')) line = line.substring(0, line.length - 1)
  try {
    const value = JSON.parse(line)
    if (typeof value !== 'string') return null
    const closingQuoteIndex = line.lastIndexOf('"')
    if (closingQuoteIndex === -1) {
      throw new TechnicalError('Expected string successfully parsed as JSON to end with a quote', {
        context: { value },
      })
    }
    return { closingQuoteIndex, value }
  } catch (_err: unknown) {
    return null
  }
}
